import { NavigationArrows } from "../../components/SemanticTheme/NavigationArrows";
import { GvJourneyStep } from "../../interfaces/giftvouchers";
import { useRouter } from "next/router";
import {
  useGvActiveStepSelector,
  useGVNextFromActiveStepSelector,
  useGVPreviousFromActiveStepSelector,
} from "@/store/selectors";

export type BookingJourneyNavigationArrowsProps = {
  disablePrevious?: boolean;
  disableNext?: boolean;
  navTitle?: string;
};

export const GVJourneyNavigationArrows: React.FC<
  BookingJourneyNavigationArrowsProps
> = ({
  disablePrevious,
  disableNext,
  navTitle,
}: BookingJourneyNavigationArrowsProps): JSX.Element => {
  const history = useRouter();

  const previousStep = useGVPreviousFromActiveStepSelector();
  const nextStep = useGVNextFromActiveStepSelector();
  const activeStep = useGvActiveStepSelector();

  const handleQueryGenerationAndRedirection = (step: GvJourneyStep) => {
    const pathname = step?.url || "";
    history.push(pathname);
  };

  return (
    <NavigationArrows
      navTitle={navTitle}
      onPreviousClick={() =>
        !!previousStep?.url
          ? handleQueryGenerationAndRedirection(previousStep)
          : undefined
      }
      onNextClick={() =>
        !!nextStep?.url
          ? handleQueryGenerationAndRedirection(nextStep)
          : undefined
      }
      disablePrevious={disablePrevious || !previousStep}
      disableNext={disableNext || !nextStep || !activeStep?.completed}
    />
  );
};
