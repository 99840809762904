import { NavigationArrows } from "../../components/SemanticTheme/NavigationArrows";
import React from "react";
import { useRouter } from "next/router";
import { StepperTag } from "../../interfaces/booking";
import { BookingJourneyStep } from "../../interfaces/booking";
import { generateBookingQueryString } from "../../utils/common";
import { LocationAvailabilitySummary_S as LocationAvailabilitySummary } from "@generated/types";
import {
  useBookingSearchDateRange,
  useActiveStepSelector,
  useAdditionalBookingJourneyDataSelector,
  useNextFromActiveStepSelector,
  usePreviousFromActiveStepSelector,
  useSearchLocationsSelector,
  useBookingSearchGuests,
  useBookingSearchDda,
} from "@/store/selectors";

export type BookingJourneyNavigationArrowsProps = {
  disablePrevious?: boolean;
  disableNext?: boolean;
  navTitle?: string;
};

export const BookingJourneyNavigationArrows: React.FC<
  BookingJourneyNavigationArrowsProps
> = ({
  disablePrevious,
  disableNext,
  navTitle,
}: BookingJourneyNavigationArrowsProps): JSX.Element => {
  const history = useRouter();

  const previousStep = usePreviousFromActiveStepSelector();
  const nextStep = useNextFromActiveStepSelector();
  const activeStep = useActiveStepSelector();
  const dateRange = useBookingSearchDateRange();
  const guests = useBookingSearchGuests();
  const dda = useBookingSearchDda();
  const additionalBookingJourneyData =
    useAdditionalBookingJourneyDataSelector();
  const { data: searchLocationsData } = useSearchLocationsSelector();

  const handleQueryGenerationAndRedirection = (step: BookingJourneyStep) => {
    const pathname = step?.url || "";
    let search = "";
    let locationIds: string[] = [];
    switch (step.tag) {
      case StepperTag.locations:
        locationIds = (
          searchLocationsData?.locations ||
          ([] as LocationAvailabilitySummary[])
        ).map((location) => location.locationId);
        break;
      case StepperTag.cabins:
        if (additionalBookingJourneyData?.cabins?.locationId) {
          locationIds = [additionalBookingJourneyData?.cabins?.locationId];
        }
        break;
    }

    if (!!locationIds.length) {
      search = generateBookingQueryString({
        startDate: dateRange.startDateISO || "",
        endDate: dateRange.endDateISO || "",
        locationIds,
        guests: guests,
        dda: dda,
      });
    }

    if (search) {
      history.push({
        pathname,
        search,
      });
    } else {
      history.push(pathname);
    }
  };

  return (
    <NavigationArrows
      navTitle={navTitle}
      onPreviousClick={() =>
        !!previousStep?.url
          ? handleQueryGenerationAndRedirection(previousStep)
          : undefined
      }
      onNextClick={() =>
        !!nextStep?.url
          ? handleQueryGenerationAndRedirection(nextStep)
          : undefined
      }
      disablePrevious={disablePrevious || !previousStep}
      disableNext={disableNext || !nextStep || !activeStep?.completed}
    />
  );
};

export default BookingJourneyNavigationArrows;
