import React from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: "0px",
  },
  mobile: {
    textAlign: "center",
    color: theme.palette.common.white,
  },
  journeyStepper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(0.8),
    paddingBottom: theme.spacing(0.8),
    "& >div": {
      order: 2,
      padding: theme.spacing(0, 1),
    },
    "& >button": {
      "& svg": {
        width: 40,
        height: 40,
      },
    },
    "& >button:nth-child(2)": {
      order: 1,
    },
    "& >button:nth-child(3)": {
      order: 3,
    },
  },
}));

export type NavigationArrowsProps = {
  onPreviousClick?: () => void;
  onNextClick?: () => void;
  disablePrevious?: boolean;
  disableNext?: boolean;
  hidePrevious?: boolean;
  hideNext?: boolean;
  navTitle?: string;
};

export const NavigationArrows: React.FC<NavigationArrowsProps> = ({
  onPreviousClick,
  onNextClick,
  disablePrevious = false,
  disableNext = false,
  hidePrevious,
  hideNext,
  navTitle,
}) => {
  const classes = useStyles();

  const disablePreviousArrow = onPreviousClick ? disablePrevious : true;
  const disableNextArrow = onNextClick ? disableNext : true;
  return (
    <div className={classes.journeyStepper}>
      {!hidePrevious && (
        <IconButton
          onClick={onPreviousClick ? () => onPreviousClick() : undefined}
          disableRipple
          className={classes.iconButton}
          disabled={disablePreviousArrow}
        >
          <ArrowBackIosNewIcon
            color={disablePreviousArrow ? "disabled" : "secondary"}
            fontSize="large"
          />
        </IconButton>
      )}
      {navTitle && (
        <Typography className={classes.mobile}>{navTitle}</Typography>
      )}
      {!hideNext && (
        <IconButton
          onClick={onNextClick ? () => onNextClick() : undefined}
          disableRipple
          className={classes.iconButton}
          disabled={disableNextArrow}
        >
          <ArrowForwardIos
            color={disableNextArrow ? "disabled" : "secondary"}
            fontSize="large"
          />
        </IconButton>
      )}
    </div>
  );
};
