import { formatPriceString } from "@components/Utils";
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";

export type FHCurrencyProps = {
  currencySymbol?: string;
  price?: number;
  children?: React.ReactNode;
};

const isValidNumber = (num: ReactNode | number) => typeof num === "number";
const isDefined = (num?: ReactNode | number) => typeof num !== "undefined";

export const FHCurrency: React.FC<FHCurrencyProps> = ({
  currencySymbol = "£",
  children,
  price,
}: PropsWithChildren<FHCurrencyProps>) => {
  const [formattedPrice, setFormattedPrice] = useState<string>();

  useEffect(() => {
    let prePrice: number | undefined = price;

    if (
      !isDefined(prePrice) &&
      isDefined(children) &&
      isValidNumber(children)
    ) {
      prePrice = children as number;
    }

    if (isDefined(prePrice)) {
      setFormattedPrice(prePrice?.toFixed(Number.isInteger(prePrice) ? 0 : 2));
    } else {
      console.error(
        `FHCurrency: no price defined as valid number in either 'price' property or as child (${
          price || children
        })`,
      );
    }
  }, [children, price]);

  return (
    <>
      {currencySymbol}
      {(formattedPrice ? formatPriceString(formattedPrice) : formattedPrice) ||
        (price ? formatPriceString(price as unknown as string) : price) ||
        (children ? formatPriceString(children as string) : children)}
    </>
  );
};
